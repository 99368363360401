/* global angular, config */

'use strict';

angular.module('managerApp.auth').factory('authInterceptor', function ($q, $cookies, $location, Util) {
    return {
        request(req) {
            req.headers = req.headers || {};
            req.headers['x-from'] = 'bo';
            if (config.isPartnerSession) {
                req.headers['x-partner'] = 1;
                if (req.headers['x-webapp-req'] === 1) {
                    delete req.headers['x-webapp-req'];
                    //req.headers['x-rfrom'] = 'bo';
                    req.headers['x-from'] = 'site-web';                    
                    req.headers['x-webapp-token'] = $cookies.get(config.app.cookies.webappToken);
                }
            }
            if ($cookies.get(config.auth.token) && Util.isSameOrigin(req.url, [config.websiteURL]) && (req.url !== config.websiteURL + '/api/taxes')) {
                req.headers.Authorization = 'Bearer ' + $cookies.get(config.auth.token);
            } else if (req.url === config.websiteURL + '/api/taxes') {
                req.headers.Authorization = 'Bearer ' + config.taxesAPIToken;
            }
            return req;
        },
        responseError(response) {
            if (response.status === 401) {
                $location.path('/logout');
            }
            return $q.reject(response);
        }
    };
});